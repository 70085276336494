import React, { useState, useEffect } from "react";
import { FaBriefcase, FaMapMarkerAlt, FaClock, FaRegClock, FaChevronRight, FaStar } from "react-icons/fa";
import OffreModal from "./ OffreModal";
import { useQueryClient } from '@tanstack/react-query';

const Offre = ({ offre }) => {
    const [isConsulted, setIsConsulted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        const checkFavorite = async () => {
            try {
                const response = await fetch(`/external-api/isFavorite/${offre.id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setIsFavorite(data.isFavorite);
                }
            } catch (error) {
                console.error('Erreur lors de la vérification du favori:', error);
            }
        };
        checkFavorite();
    }, [offre.id]);

    const handleConsult = () => {
        setIsConsulted(true);
        setIsModalOpen(true);
    };

    const toggleFavorite = async () => {
        try {
            const url = `/external-api/toggleFavorite/${offre.id}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.ok) {
                setIsFavorite(!isFavorite);
                await queryClient.invalidateQueries('favorites');
            } else {
                throw new Error('Erreur lors du toggle du favori');
            }
        } catch (error) {
            console.error('Erreur lors du toggle du favori:', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow-sm">
            <div className="flex flex-col md:flex-row justify-between items-start">
                <div className="flex-grow w-full md:w-auto">
                    <div className="flex justify-between items-start md:items-center">
                        <h3 className="text-lg font-semibold text-gray-800">{offre.intitule}</h3>
                        <button
                            className="md:hidden text-gray-500 p-2"
                            onClick={handleConsult}
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                    <p className="text-sm text-gray-600">{offre.entreprise.nom || "Entreprise non disponible"}</p>

                    <div className="flex flex-col md:flex-row justify-between mt-2">
                        <div className="order-2 md:order-1">
                            <p className="text-sm text-gray-700 leading-snug max-h-24 overflow-hidden w-full md:w-[46rem] line-clamp-4 mb-2 md:mb-0">
                                {offre.description}
                            </p>
                        </div>
                        <div className="order-1 md:order-2 text-sm space-y-2 md:ml-4 flex-shrink-0 md:w-40 whitespace-nowrap mb-3 md:mb-0">
                            <div className="flex items-center">
                                <FaBriefcase className="mr-2 text-blue-500"/>
                                <p className="font-medium text-blue-500">{offre.typeContrat === "MIS" ? "Intérim" : offre.typeContrat === "SAI" ? "Saisonnier" : offre.typeContrat === "DIN" ? "CDI Intérimaire" : offre.typeContrat}</p>
                            </div>
                            <div className="flex items-center">
                                <FaMapMarkerAlt className="mr-2 text-blue-500"/>
                                <p className="text-blue-500">
                                    {offre.lieuTravail.libelle.split(" - ").reverse().join(" (") + ")"}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <FaClock className="mr-2 text-blue-500"/>
                                <p className="text-blue-500">{offre.dureeTravailLibelleConverti || 'Durée de travail non disponible'}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row md:flex-col items-center md:items-end space-y-0 md:space-y-14 mt-4 md:mt-0 w-full md:w-auto">
                    <div className="flex items-center gap-2 flex-wrap justify-center md:justify-end w-full md:w-auto">
                        { !isConsulted && (
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                                Nouveau
                            </span>
                        )}
                        {isConsulted && (
                            <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded">
                                Consulté
                            </span>
                        )}
                        <button
                            onClick={toggleFavorite}
                            className={`flex items-center gap-1 ${isFavorite ? 'bg-yellow-100 text-orange-300' : 'bg-blue-100 text-blue-800'} text-xs font-medium px-2.5 py-0.5 rounded focus:outline-none`}
                        >
                            <FaStar className={`text-sm ${isFavorite ? 'text-orange-400' : 'text-blue-500'}`} />
                            Favori
                        </button>
                    </div>
                    <button
                        className="hidden md:flex items-center text-gray-500 p-2 rounded"
                        onClick={handleConsult}
                    >
                        <FaChevronRight className="ml-2" />
                    </button>
                </div>
            </div>
            <div className="flex text-xs text-gray-400 mt-2">
                <FaRegClock className="mr-2 mt-0.5 text-xs text-gray-400" />
                {offre.dateCreation ? `Publié le ${new Date(offre.dateCreation).toLocaleDateString()}` : 'Date de publication non disponible'}
            </div>

            <OffreModal offre={offre} isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
    );
};

export default Offre;